import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import options from "../../components/richtext";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Video from "../../components/video";
import { Link } from "gatsby";

const Artist = ({ data }) => {
  // highlight-line
  return (
    <div>
      <Seo title="Info" />
      <ul class="nav nav-pills mb-5">
        <li class="nav-item">
          <Link className="nav-link" activeClassName="active" to="../bio/">
            Bio
          </Link>
        </li>
        <li class="nav-item">
          <Link className="nav-link" activeClassName="active" to="../studio/">
            Studio
          </Link>
        </li>
        <li class="nav-item">
          <Link className="nav-link" activeClassName="active" to="../contact/">
            Contact
          </Link>
        </li>
      </ul>
      <article>
        <h2>{data.contentfulInfo.name}</h2>
        <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{
              __html:
                data.contentfulInfo.content
                  .childrenMarkdownRemark[0].html
            }}
          />
      </article>
    </div>
  );
};

export default Artist;

export const query = graphql`
  query($slug: String) {
    contentfulInfo(slug: { eq: $slug }) {
      id
      title
            content {
        childrenMarkdownRemark {
          html
        }
      }
    }
  }
`;
